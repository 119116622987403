@import "./assets/css/reset.css";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:400,700&display=swap");

html {
  display: flex;
  flex-direction: column;
  height: auto;
  font-family: "Roboto Slab", serif;
  line-height: 1.2;
}

body {
  margin: 0;
  padding: 0;
}

/* Scroll OSLike FF IE unsupport */
::-webkit-scrollbar {
  background-color: #fff;
  width: 8px;
}
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 8px;
  border: 2px solid #fff;
}
::-webkit-scrollbar-button {
  display: none;
}

.MuiDialog-paperFullScreen {
  background: #fbfbfb !important;
}
